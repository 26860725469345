export const BE_URL_PROD='https://ic38.insurance101.in/web/v1'
export const BE_URL_DEV='https://ic38-dev.insurance101.in/web/v1'

export const baseUrl=()=>{
    let hostPrefix=window.location.host.split('.')[0] || ''
    let url = "";
    switch (hostPrefix) {
      case 'www':
        url = BE_URL_PROD;
        break;
      default:
        // todo for nxt release
        // url = BE_URL_DEV;

        url = BE_URL_PROD;
        break;
    }
    return url;
}

export const getData='/profile'
