import React, { useEffect, useState } from "react";
import TOP_BANNER_STUDENT from "./assets/top_banner_student.jpg";
import TOP_BANNER_NON_STUDENT from "./assets/top_banner_non_student.jpg";
import GOOGLE_PLAY from "./assets/google_play.png";
import { useLocation } from "react-router-dom";
import Utils from "./Utils/utils";
import { baseUrl, getData } from "./Utils/constants";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [eventSend, setEventSend] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [banner, setBanner] = useState<string>(TOP_BANNER_STUDENT);
  const token = new URLSearchParams(useLocation().search).get("token");
  const lang = new URLSearchParams(useLocation().search).get("lang");
  useEffect(() => {
    if (token) {
      getUserData();
    }
    let sessionId = "";
    window.addEventListener(
      "androidEvent",
      function (e: any) {
        if (e.detail && e.detail.token) {
          sessionId = e.detail.token;
          console.log('sessionId => ',sessionId)
          getUserData(sessionId);
        }
      },
      false
    );
  }, []);
  const getUserData = async (sessionId?: string) => {
    let authToken = "";
    if (token) {
      authToken = token;
    }
    if (sessionId) {
      authToken = sessionId;
    }
    setIsLoading(true);
    if (authToken) {
      let data = await Utils.Get(baseUrl(), getData, authToken);
      if (data.success && data.data) {
        setUserData(data.data);
        if (data.data.occupation) {
          handleBanner(data.data.occupation);
          sendEvent(data.data.email);
        }
      }
    }
    setIsLoading(false);
  };
  const sendEvent = (email?: string) => {
    let eventData: any = {};
    if (email) {
      eventData.email = email;
    }
    if (!eventSend) {
      setEventSend(true);
      Utils.trackEvent("Web_GroMo_cross_sell_page_open", eventData);
    }
  };
  const handleBanner = (occupation: string) => {
    switch (occupation) {
      case "Student/Not working":
        setBanner(TOP_BANNER_STUDENT);
        break;
      default:
        setBanner(TOP_BANNER_STUDENT);
        break;
    }
  };

  const whatIsGromo = [
    "GroMo helps you earn over Rs.50,000 each month by selling simple financial products to your customers.",
    "You can open Demat accounts, Savings accounts, Credit Cards, Digital gold, and more for your customer and earn money on each sale.",
    "For example- Open a Demat account of your customer from the list of brands in the GroMo app and earn up to Rs.1,000 on each account.",
    "Over 20+ products are available to sell.",
  ];
  const gromoBenefits = [
    "Earn without any investment for free. No upfront fees or hidden charges to be paid.",
    "Learn about different financial products and their features.",
    "Attend regular training & webinar to learn how to sell these products and how to get customers.",
    "Free digital Courses & certifications on the application.",
    "Get your personalized visiting card and your personal website for free.",
  ];
  const url = `https://xjw5l.app.link/VqDLzBVkjob`;

  const openURL = (from: string) => {
    let eventData: any = {};
    if (userData && userData.email) {
      eventData.email = userData.email;
    }
    Utils.trackEvent(`Web_GroMo_${from}_clicked`, eventData);
    try {
      if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        window.location.href = url;
      }
    } catch (e) {}
  };
  return (
    <div>
      {!isLoading ? (
        <div>
          {/* top bar start */}
          {/* <nav className="navbar navbar-dark bg-primary position-fixed top-0 start-0 end-0 py-3 ">
            <div className="container-fluid">
              <div>
                <i className="bi bi-arrow-left text-light"></i>
                <span className="navbar-brand mb-0 h1 px-2">Navbar</span>
              </div>
            </div>
          </nav> */}
          {/* top bar end */}
          {/* main content start */}
          <div>
            {/* top banner start */}
            <div
              style={{
                padding: "0px 0 68px 0",
              }}
            >
              {banner ? (
                <div onClick={() => openURL("banner")}>
                  <img src={banner} className="w-100 mw-100" alt="" />
                </div>
              ) : null}

              {/* top banner end */}
              {/* heading start */}
              <div>
                <div className="p-2 pb-3 pt-2">
                  <p
                    className="fw-bold text-left mb-1"
                    style={{
                      fontSize: 16,
                    }}
                  >
                    What is GroMo?
                  </p>
                  <div>
                    {whatIsGromo.length
                      ? whatIsGromo.map((g, i) => {
                          return (
                            <div
                              key={i}
                              className="d-flex p-1 align-items-center"
                            >
                              <i
                                className="bi bi-brightness-low"
                                style={{
                                  fontSize: 22,
                                }}
                              ></i>
                              <div
                                className="px-2"
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                {g}
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="p-2 pb-3 pt-2">
                  <p
                    className="fw-bold text-left mb-1"
                    style={{
                      fontSize: 16,
                    }}
                  >
                    How is GroMo beneficial for me?
                  </p>
                  <div>
                    {gromoBenefits.length
                      ? gromoBenefits.map((g, i) => {
                          return (
                            <div key={i} className="d-flex p-1 ">
                              <div
                                style={{
                                  fontSize: 14,
                                  paddingTop: 1,
                                }}
                              >
                                {i + 1}.
                              </div>
                              <div
                                className="px-2"
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                {g}
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>

            {/* heading end */}
          </div>
          {/* main content end */}
          <div className="position-fixed bottom-0 start-0 end-0 p-3">
            <button
              className="btn btn-dark w-100 d-flex justify-content-center align-items-center"
              onClick={() => openURL("download_now")}
            >
              <div>
                <img
                  src={GOOGLE_PLAY}
                  alt=""
                  className="px-2"
                  style={{
                    height: 28,
                  }}
                />
              </div>
              <div
                style={{
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                <div
                  style={{
                    fontSize: 12,
                    lineHeight: "15px",
                  }}
                >
                  GET IT ON
                </div>
                <div
                  style={{
                    fontSize: 16,
                    lineHeight: "16px",
                  }}
                >
                  Google Play
                </div>
              </div>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default App;
