import { autocompleteClasses, Button, Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Select, { MultiValue } from "react-select";
import { useState } from "react";

import AppDotsLoading from "../../components/AppDotsLoading";
import { GROMO_BG, GROMO_ICON } from "../../images/Images";
import AppNavBar from "../../components/AppNavBar";
import { PAYOUT } from "./PayoutInterface";

const columns: GridColDef[] = [
  {
    field: "insurer",
    headerName: "Insurer",
    flex: 0.6,
    headerAlign: "center",
    minWidth: 120,
  },
  {
    field: "productType",
    headerName: "Product Type",
    flex: 0.6,
    headerAlign: "center",
    minWidth: 120,
  },
  {
    field: "commissionPercent",
    headerName: "Commission %",
    flex: 0.5,
    headerAlign: "center",
    minWidth: 100,
  },
  {
    field: "ncb",
    headerName: "NCB",
    flex: 0.3,
    headerAlign: "center",
    minWidth: 60,
  },
  {
    field: "rtoInclude",
    headerName: "RTO Included",
    flex: 1, 
    headerAlign: "center",
    minWidth: 200,
  },
  {
    field: "rtoExclude",
    headerName: "RTO Excluded",
    flex: 0.4, 
    headerAlign: "center",
    minWidth: 80,
  },
  {
    field: "comments",
    headerName: "Comments",
    sortable: false,
    flex: 1.5,
    headerAlign: "center",
    minWidth: 300
  },
];

const vehicleTypes = [{ value: "4W", label: "Car" }];

const fuelTypes = [
  { value: "Petrol", label: "Petrol" },
  { value: "Diesel", label: "Diesel" },
  { value: "EV", label: "EV" },
];

const insurers = [
  { value: "ICICI Lombard", label: "ICICI Lombard" },
  { value: "Tata AIG", label: "Tata AIG" },
  { value: "Reliance General", label: "Reliance General" },
  { value: "Go Digit General", label: "Go Digit General" },
];

const productTypes = [
  { value: "Comprehensive", label: "Comprehensive" },
  { value: "Zero Dep", label: "Zero Dep" },
  { value: "Third Party", label: "Third Party" },
  { value: "Standalone OD", label: "Standalone OD" },
];

function Calculate() {
  const [payout, setPayout] = useState<PAYOUT[]>([]);
  const [vehicleType, setVehicleType] = useState("");
  const [rto, setRto] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [insurer, setInsurer] = useState("");
  const [productType, setProductType] = useState("");
  const [ncb, setNcb] = useState("");
  const [loading, setLoading] = useState(false);
  const [vehicleTypeErrorMessage, setVehicleTypeErrorMessage] = useState("");
  const [rtoErrorMessage, setRtoErrorMessage] = useState("");
  const [fuelTypeErrorMessage, setFuelTypeErrorMessage] = useState("");
  let rtoCode = "";

  function handleVehicleTypeChange(event: any) {
    setVehicleTypeErrorMessage("");
    setVehicleType(event.value);
  }

  function handleRtoChange(event: any) {
    setRtoErrorMessage("");
    setRto(event.target.value);
  }

  function handleFuelTypeChange(event: any) {
    setFuelTypeErrorMessage("");
    setFuelType(event.value);
  }

  function handleInsurerChange(
    event: MultiValue<{ value: string; label: string }>
  ) {
    const insurerList = Array.from(event).map((o) => o.value);
    setInsurer(insurerList.join(","));
  }

  function handleProductTypeChange(
    event: MultiValue<{ value: string; label: string }>
  ) {
    const productTypeList = Array.from(event).map((o) => o.value);
    setProductType(productTypeList.join(","));
  }

  function handleNcbChange(data: any) {
    setNcb(data.target.value);
  }

  function resetNcbSelection() {
    setNcb("");
  }

  function handleLoadingChange(value: boolean) {
    setLoading(value);
  }

  function handleValidation() {
    let valid = true;

    rtoCode = rto.toUpperCase();

    if (vehicleType.length == 0) {
      setVehicleTypeErrorMessage("Invalid Value");
      if (valid) {
        valid = false;
      }
    }
    if (rto.length <= 1 || rto.length == 3) {
      setRtoErrorMessage("Enter first two characters");
      if (valid) {
        valid = false;
      }
    }
    else {
      if (rto.length >= 4) {
        rtoCode = rto.slice(0,4);
        const vehicleNoRegex = /^[A-Z]{2}[0-9]{2}$/
        if (!vehicleNoRegex.test(rtoCode)) {
          setRtoErrorMessage("Invalid Vehicle No or Rto Code")
          if (valid) {
            valid = false
          }
        }
      } else {
        const vehicleNoRegex = /^[A-Z]{2}$/
        if (!vehicleNoRegex.test(rtoCode)) {
          setRtoErrorMessage("Invalid Vehicle No or Rto Code")
          if (valid) {
            valid = false
          }
        }
      }
    }
    if (fuelType.length == 0) {
      setFuelTypeErrorMessage("Invalid Value");
      if (valid) {
        valid = false;
      }
    }
    return valid;
  }

  const handleClick = async () => {
    handleLoadingChange(true);
    if (!handleValidation()) {
      handleLoadingChange(false);
      return;
    }

    var data = JSON.stringify({
      rto: rtoCode,
      fuelType: fuelType,
      insurer: insurer,
      productType: productType,
      ncb: ncb,
    });

    console.log(data);

    var requestOptions: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };

//process.env.REACT_APP_API_URL

    fetch(
      process.env.REACT_APP_API_URL + "/payout?vehicleType=" + vehicleType, 
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        handleLoadingChange(false);
        let newPayout = result.map((obj: PAYOUT) => {
          return {
            ...obj, 
            commissionPercent: (Number(obj.commissionPercent) * 100).toPrecision(4),
            comments: "Slab Start: ₹" + obj.slabStart + " Slab End: ₹" + obj.slabEnd + ", " + obj.comments
           };
        });
        setPayout(newPayout);
      })
      .catch((error) => {
        handleLoadingChange(false);
        console.log("error", error);
      });
  };

  return (
    <>
      <AppNavBar logo={GROMO_ICON} />
      <Grid
        style={{
          background: `url(${GROMO_BG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          paddingTop: 72,
        }}
      >
        <div
          style={{
            padding: "12px",
          }}
        >
          <Grid
            style={{
              background: "#F3F5F9",
              maxWidth: "800px",
              alignItems: "center",
              padding: "24px",
              margin: "auto",
              display: "grid",
              boxShadow: "#80808052 0px 2px 4px 1px",
              borderRadius: 8,
              overflow: "hidden",
              transition: "0.3s",
              height: "100%",
            }}
          >
            <label>
              Vehicle Type *
              <Select
                name="vehicle"
                options={vehicleTypes}
                onChange={handleVehicleTypeChange}
              />
              {vehicleTypeErrorMessage.length != 0 && (
                <Typography
                  style={{
                    width: "100%",
                    color: "red",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {" "}
                  {fuelTypeErrorMessage}
                </Typography>
              )}
            </label>
            <br></br>
            <label>
              Enter Vehicle Number or RTO Code (Minimum 2 Characters)*
              <input
                style={{
                  padding: "8px 12px",
                  border: "1px solid lightgray",
                  borderRadius: 4,
                  fontSize: "15px",
                  transition: ".3s",
                  width: "100%",
                }}
                placeholder="Type here"
                value={rto}
                onChange={handleRtoChange}
              />
            </label>
            {rtoErrorMessage.length != 0 && (
              <Typography
                style={{
                  width: "100%",
                  color: "red",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                {" "}
                {rtoErrorMessage}
              </Typography>
            )}
            <br></br>
            <label>
              Vehicle Fuel Type *
              <Select
                name="fuel"
                options={fuelTypes}
                onChange={handleFuelTypeChange}
              />
              {fuelTypeErrorMessage.length != 0 && (
                <Typography
                  style={{
                    width: "100%",
                    color: "red",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {" "}
                  {fuelTypeErrorMessage}
                </Typography>
              )}
            </label>
            <br></br>
            <label>
              Insurer (optional)
              <Select
                isMulti
                name="insurer"
                options={insurers}
                onChange={handleInsurerChange}
              />
            </label>
            <br></br>
            <label>
              Product Type (optional)
              <Select
                isMulti
                name="product"
                options={productTypes}
                onChange={handleProductTypeChange}
              />
            </label>
            <br></br>
            <label>
              NCB (optional)
              <span>
                <input
                  value="Yes"
                  checked={ncb === "Yes"}
                  onChange={handleNcbChange}
                  type="radio"
                  name="ncb"
                  style={{
                    margin: "0 4px 0 16px",
                  }}
                />
                Yes
              </span>
              <span>
                <input
                  value="No"
                  checked={ncb === "No"}
                  onChange={handleNcbChange}
                  type="radio"
                  name="ncb"
                  style={{
                    margin: "0 4px 0 16px",
                  }}
                />
                No
              </span>
              <Button
                onClick={resetNcbSelection}
                style={{
                  color: "gray",
                  margin: "0 8px",
                }}
              >
                Reset
              </Button>
            </label>
            <br></br>
            <Button
              onClick={handleClick}
              disabled={
                vehicleType.length == 0 ||
                rto.length == 0 ||
                fuelType.length == 0
              }
              variant="contained"
              disableElevation={true}
              style={{
                width: "100%",
              }}
            >
              Get Payout
            </Button>
          </Grid>
        </div>
        <Grid
          style={{
            alignItems: "center",
            height: "100%",
            width: "100%",
            padding: "24px 12px 48px 12px",
            display: "flex"
          }}
        >
          
          <DataGrid
            rows={payout}
            columns={columns}
            pageSize={25}
            autoHeight
            getRowHeight={() => "auto"}
            rowHeight={50}
            showCellRightBorder
            showColumnRightBorder
            rowsPerPageOptions={[10]}
            getRowId={(row: any) =>
              row.insurer +
              row.productType +
              row.commissionPercent +
              row.ncb +
              row.comments
            }
            rowSpacingType="border"
            sx={{
              fontSize: "16px",
              color: "black",
              background: "#F3F5F9",
              padding: "16px",
              boxShadow: "#80808052 0px 2px 4px 1px",
              borderRadius: 2,
            }}
          />
          {loading && <AppDotsLoading />}
        </Grid>
      </Grid>
    </>
  );
}

export default Calculate;
